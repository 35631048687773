var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./malli.registry.js");require("./malli.util.js");
'use strict';var RC,SC,TC,Mga,UC,WC,XC;
$CLJS.QC=function(a,b){return function(){function c(l,m,t){l=null==l?b:l;return a.j?a.j(l,m,t):a.call(null,l,m,t)}function d(l,m){l=null==l?b:l;return a.g?a.g(l,m):a.call(null,l,m)}function e(l){l=null==l?b:l;return a.h?a.h(l):a.call(null,l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var C=Array(arguments.length-3);A<C.length;)C[A]=arguments[A+3],++A;A=new $CLJS.w(C,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.Ne.M(a,null==t?b:t,u,
v,x)}l.A=3;l.B=function(t){var u=$CLJS.z(t);t=$CLJS.B(t);var v=$CLJS.z(t);t=$CLJS.B(t);var x=$CLJS.z(t);t=$CLJS.Hc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.w(x,0,null)}return k.l(l,m,t,v)}throw Error("Invalid arity: "+arguments.length);
};f.A=3;f.B=k.B;f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};RC=function(a,b){this.db=a;this.Ch=b;this.C=393216;this.I=0};SC=function(a){for(var b=null;;){var c;a=$CLJS.Gn.g(a,b);a=c=$CLJS.fn(a)?$CLJS.$m(a):a;if(!$CLJS.fn(c))return a}};TC=function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return!($CLJS.td(a)&&!0===$CLJS.yr.h(a))};
Mga=function(a,b,c){var d=SC($CLJS.Gn.g(a,c));return $CLJS.Cp($CLJS.Rm(d),$CLJS.Om(d),function(){var e=$CLJS.Qm(d);return b.h?b.h(e):b.call(null,e)}(),function(){var e=$CLJS.Pm($CLJS.Gn.g(d,null));return $CLJS.n(e)?e:c}())};UC=function(a){return function(b,c,d){var e=$CLJS.y(c);b=$CLJS.z(e);e=$CLJS.B(e);c=$CLJS.gk.g(function(f){return $CLJS.Gn.g(f,d)},c);return new $CLJS.P(null,3,5,$CLJS.Q,[c,$CLJS.gk.g($CLJS.Ur,c),$CLJS.cb(function(f,k){return a.j?a.j(f,k,d):a.call(null,f,k,d)},b,e)],null)}};
$CLJS.VC=function(a,b,c,d,e,f,k,l,m,t,u,v,x,A,C,G,J,S,X){this.form=a;this.options=b;this.Ne=c;this.forms=d;this.U=e;this.Xd=f;this.za=k;this.children=l;this.min=m;this.Eb=t;this.Rb=u;this.parent=v;this.Ti=x;this.re=A;this.type=C;this.cache=G;this.max=J;this.Ve=S;this.bi=X;this.C=393216;this.I=0};WC=function(a,b,c,d,e,f,k,l,m){this.Ve=a;this.re=b;this.type=c;this.min=d;this.max=e;this.Xd=f;this.Eb=k;this.Rb=l;this.Ne=m;this.C=393216;this.I=0};
XC=function(a){var b=$CLJS.O(a),c=$CLJS.K.g(b,$CLJS.Si),d=$CLJS.K.g(b,$CLJS.si),e=$CLJS.K.g(b,$CLJS.Bj),f=$CLJS.K.g(b,$CLJS.vs),k=$CLJS.K.g(b,$CLJS.cp),l=$CLJS.K.g(b,$CLJS.Ai);return new WC(a,b,c,d,e,f,k,l,new $CLJS.h(null,1,[$CLJS.Si,$CLJS.Io],null))};$CLJS.Y=function(a,b){$CLJS.zh.v(YC,$CLJS.R,a,b);$CLJS.Te($CLJS.ZC,$CLJS.N);return null};RC.prototype.P=function(a,b){return new RC(this.db,b)};RC.prototype.O=function(){return this.Ch};RC.prototype.cd=$CLJS.yc;
RC.prototype.Pc=function(a,b){return $CLJS.Am($CLJS.Cm($CLJS.q(this.db)),b)};var $C=function $C(a){switch(arguments.length){case 2:return $C.g(arguments[0],arguments[1]);case 3:return $C.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$C.g=function(a,b){return $C.j(a,b,null)};
$C.j=function(a,b,c){function d(v,x){return $CLJS.E.g($CLJS.Aj,v)?new $CLJS.P(null,2,5,$CLJS.Q,[null,x],null):$CLJS.bf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Gp.h(x)],null),$CLJS.Hp.h(x))}function e(v,x){return $CLJS.n($CLJS.n(v)?x:v)?$CLJS.wk.l($CLJS.H([v,x])):$CLJS.n(v)?v:x}a=$CLJS.n(a)?SC($CLJS.Gn.g(a,c)):null;b=$CLJS.n(b)?SC($CLJS.Gn.g(b,c)):null;var f=$CLJS.n(a)?$CLJS.sn.h(a):null,k=$CLJS.n(b)?$CLJS.sn.h(b):null,l=$CLJS.O(c),m=$CLJS.K.j(l,$CLJS.rs,function(v,x){return x}),t=$CLJS.K.j(l,$CLJS.us,
function(v,x){return x});if(null==a)return b;if(null==b)return a;if($CLJS.Ra(function(){var v=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.lr,null,$CLJS.Aj,null],null),null);v=v.h?v.h(f):v.call(null,f);return $CLJS.n(v)?(v=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.lr,null,$CLJS.Aj,null],null),null),v.h?v.h(k):v.call(null,k)):v}()))return m.j?m.j(a,b,c):m.call(null,a,b,c);if($CLJS.E.g(f,$CLJS.Aj)&&$CLJS.E.g(k,$CLJS.Aj)){l=e($CLJS.Om(a),$CLJS.Om(b));var u=$CLJS.Se($CLJS.Sg);a=$CLJS.cb(function(v,x){var A=
$CLJS.I(x,0,null);if($CLJS.n(function(){var C=$CLJS.q(u);return C.h?C.h(A):C.call(null,A)}()))return $CLJS.cb(function(C,G){var J=$CLJS.I(G,0,null),S=$CLJS.Yd.g;if($CLJS.E.g(J,A)){J=$CLJS.I(G,0,null);var X=$CLJS.I(G,1,null),W=$CLJS.I(G,2,null);$CLJS.I(x,0,null);var da=$CLJS.I(x,1,null),ra=$CLJS.I(x,2,null);G=TC(G);var Ma=TC(x);G=t.g?t.g(G,Ma):t.call(null,G,Ma);X=$CLJS.wk.l($CLJS.H([X,da]));ra=new $CLJS.P(null,3,5,$CLJS.Q,[J,$CLJS.R.j(X,$CLJS.yr,$CLJS.Ra(G)),$C.j?$C.j(W,ra,c):$C.call(null,W,ra,c)],
null);J=$CLJS.I(ra,0,null);W=$CLJS.I(ra,1,null);ra=$CLJS.I(ra,2,null);J=$CLJS.Ra(ra)?new $CLJS.P(null,2,5,$CLJS.Q,[J,W],null):$CLJS.n($CLJS.n(W)?!1===$CLJS.yr.h(W)&&$CLJS.E.g(1,$CLJS.D(W)):W)?new $CLJS.P(null,2,5,$CLJS.Q,[J,ra],null):$CLJS.Ra($CLJS.y(W))?new $CLJS.P(null,2,5,$CLJS.Q,[J,ra],null):!1===$CLJS.yr.h(W)?new $CLJS.P(null,3,5,$CLJS.Q,[J,$CLJS.Xj.g(W,$CLJS.yr),ra],null):new $CLJS.P(null,3,5,$CLJS.Q,[J,W,ra],null)}else J=G;return S.call($CLJS.Yd,C,J)},$CLJS.xf,v);$CLJS.zh.j(u,$CLJS.Yd,A);return $CLJS.Yd.g(v,
x)},$CLJS.xf,$CLJS.Wf.g($CLJS.Qm(a),$CLJS.Qm(b)));return $CLJS.Cp($CLJS.Aj,l,a,c)}return function(v,x){var A=$CLJS.y(v);v=$CLJS.z(A);var C=$CLJS.B(A);A=$CLJS.z(C);C=$CLJS.B(C);var G=$CLJS.y(x);x=$CLJS.z(G);var J=$CLJS.B(G);G=$CLJS.z(J);J=$CLJS.B(J);return $CLJS.Cp($CLJS.lr,e(v,x),$CLJS.bf.l(new $CLJS.P(null,1,5,$CLJS.Q,[$C.j(A,G,c)],null),C,$CLJS.H([J])),c)}(d(f,a),d(k,b))};$C.A=3;
var aD=function aD(a){switch(arguments.length){case 2:return aD.g(arguments[0],arguments[1]);case 3:return aD.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};aD.g=function(a,b){return aD.j(a,b,null)};
aD.j=function(a,b,c){return $C.j(a,b,$CLJS.hk.j($CLJS.hk.j(c,$CLJS.rs,$CLJS.QC($CLJS.Pd,function(d,e,f){var k=$CLJS.E.g($CLJS.Ur.g(d,null),$CLJS.Ur.g(e,null));return $CLJS.n(k)?d:$CLJS.Gn.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,d,e],null),f)})),$CLJS.us,$CLJS.QC($CLJS.Pd,function(d,e){return $CLJS.n(d)?e:d})))};aD.A=3;
var bD=function bD(a){switch(arguments.length){case 2:return bD.g(arguments[0],arguments[1]);case 3:return bD.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};bD.g=function(a,b){return bD.j(a,b,null)};bD.j=function(a,b,c){var d=$CLJS.Wg(b);return Mga(a,function(e){return $CLJS.ff(function(f){f=$CLJS.I(f,0,null);return d.h?d.h(f):d.call(null,f)},e)},c)};bD.A=3;$CLJS.g=$CLJS.VC.prototype;
$CLJS.g.P=function(a,b){return new $CLJS.VC(this.form,this.options,this.Ne,this.forms,this.U,this.Xd,this.za,this.children,this.min,this.Eb,this.Rb,this.parent,this.Ti,this.re,this.type,this.cache,this.max,this.Ve,b)};$CLJS.g.O=function(){return this.bi};$CLJS.g.Ya=$CLJS.yc;$CLJS.g.ya=function(){return $CLJS.Lm(this.za)};$CLJS.g.La=function(){return this.options};$CLJS.g.Ta=function(a,b,c,d){return $CLJS.ho(this,new $CLJS.P(null,1,5,$CLJS.Q,[this.za],null),b,c,d)};$CLJS.g.$a=function(){return this.U};
$CLJS.g.va=function(){return this.children};$CLJS.g.Sa=function(){return $CLJS.q(this.form)};$CLJS.g.Ra=function(a,b){return $CLJS.Mm(this.za,b)};$CLJS.g.Za=function(){return this.parent};$CLJS.g.Wa=$CLJS.yc;$CLJS.g.ia=$CLJS.ta(5);$CLJS.g.je=$CLJS.yc;$CLJS.g.Kd=function(){return this.za};$CLJS.g=WC.prototype;$CLJS.g.P=function(a,b){return new WC(this.Ve,this.re,this.type,this.min,this.max,this.Xd,this.Eb,this.Rb,b)};$CLJS.g.O=function(){return this.Ne};$CLJS.g.Xa=$CLJS.yc;$CLJS.g.Pa=function(){return this.type};
$CLJS.g.Qa=function(){return this.Eb};$CLJS.g.Oa=function(a,b,c,d){var e=this;$CLJS.nn(e.type,b,c,e.min,e.max);a=function(){var m=$CLJS.yf(c);return e.Rb.j?e.Rb.j(b,m,d):e.Rb.call(null,b,m,d)}();var f=$CLJS.I(a,0,null),k=$CLJS.I(a,1,null),l=$CLJS.I(a,2,null);return new $CLJS.VC(new $CLJS.uh(function(){return $CLJS.Ln(e.type,b,k,d)}),d,e.Ne,k,b,e.Xd,l,f,e.min,e.Eb,e.Rb,this,a,e.re,e.type,$CLJS.Kn(),e.max,e.Ve,new $CLJS.h(null,1,[$CLJS.Si,$CLJS.gp],null))};
$CLJS.cD=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);var YC;$CLJS.ZC=$CLJS.Se($CLJS.N);
YC=$CLJS.Se($CLJS.wk.l($CLJS.H([$CLJS.wr(),new $CLJS.h(null,3,[$CLJS.ss,XC(new $CLJS.h(null,2,[$CLJS.Si,$CLJS.ss,$CLJS.Ai,UC($C)],null)),$CLJS.qs,XC(new $CLJS.h(null,2,[$CLJS.Si,$CLJS.qs,$CLJS.Ai,UC(aD)],null)),$CLJS.ws,XC(new $CLJS.h(null,5,[$CLJS.Si,$CLJS.ws,$CLJS.vs,1,$CLJS.si,2,$CLJS.Bj,2,$CLJS.Ai,function(a,b,c){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk.j(b,0,function(d){return $CLJS.Gn.g(d,c)}),$CLJS.hk.j(b,0,function(d){return $CLJS.Ur.g(d,c)}),$CLJS.Ne.g(bD,$CLJS.Yd.g(b,c))],null)}],null))],
null)])));$CLJS.Te($CLJS.Zk,$CLJS.Cm(new RC(YC,$CLJS.N)));