var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var BK,DK,FK,IK,LK;$CLJS.zK=function(a){return $CLJS.Hh($CLJS.q($CLJS.fB),a,$CLJS.pF)};$CLJS.AK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);BK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.CK=new $CLJS.M(null,"operators","operators",-2064102509);DK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);
$CLJS.EK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);FK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.GK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.HK=new $CLJS.M(null,"dimensions","dimensions",-254818097);IK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.JK=new $CLJS.M(null,"max-value","max-value",687805168);
$CLJS.KK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);LK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.MK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(DK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.wD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.cD],null)],null)],null));$CLJS.Y(LK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,DK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.BD],null)],null)],null));$CLJS.Y(FK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,$CLJS.lF],null),LK,$CLJS.rD],null));
$CLJS.Y(IK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,$CLJS.lF],null),DK,$CLJS.AD],null));
$CLJS.LF.g($CLJS.lF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,$CLJS.lF],null),DK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.AD,$CLJS.rD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.xi,function(a){return $CLJS.sd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.WA(a)):null},$CLJS.ts,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,IK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,FK],null)],null)],null));$CLJS.rE($CLJS.lF,$CLJS.pF);$CLJS.MD.m(null,$CLJS.lF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.oi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.$D});$CLJS.yE($CLJS.qA,$CLJS.H([$CLJS.rD]));$CLJS.MD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.oi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.$D});
$CLJS.rE($CLJS.qA,$CLJS.pF);$CLJS.Y(BK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.wD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.rD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.rD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.rD],null)],null)],null));
$CLJS.LF.g($CLJS.aF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,$CLJS.aF],null),BK,$CLJS.vj],null));$CLJS.MD.m(null,$CLJS.aF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.oi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.$D});$CLJS.rE($CLJS.aF,$CLJS.pF);$CLJS.yE($CLJS.JE,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jD],null)],null)]));$CLJS.rE($CLJS.JE,$CLJS.pF);
$CLJS.yE($CLJS.gF,$CLJS.H([$CLJS.Zr,$CLJS.$D,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hD],null)],null)]));$CLJS.rE($CLJS.gF,$CLJS.pF);
$CLJS.Y($CLJS.pF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.OD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.Es,function(){return["Valid reference, must be one of these clauses: ",$CLJS.hs(", ",$CLJS.Cz($CLJS.Ed,$CLJS.zk.g($CLJS.q($CLJS.fB),$CLJS.pF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.zK(a)}],null)],null));