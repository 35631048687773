var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var CX,Ona,Pna,Qna,FX,Sna,Tna,Una,Vna,HX,IX,KX,MX,Wna,NX,OX,Xna,Yna,Zna,PX,Rna,QX,RX,$na,TX;CX=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.Xe(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
$CLJS.DX=function(a,b){return function(){function c(m,t,u){return new $CLJS.P(null,2,5,$CLJS.Q,[a.j?a.j(m,t,u):a.call(null,m,t,u),b.j?b.j(m,t,u):b.call(null,m,t,u)],null)}function d(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[a.g?a.g(m,t):a.call(null,m,t),b.g?b.g(m,t):b.call(null,m,t)],null)}function e(m){return new $CLJS.P(null,2,5,$CLJS.Q,[a.h?a.h(m):a.call(null,m),b.h?b.h(m):b.call(null,m)],null)}function f(){return new $CLJS.P(null,2,5,$CLJS.Q,[a.o?a.o():a.call(null),b.o?b.o():b.call(null)],null)}
var k=null,l=function(){function m(u,v,x,A){var C=null;if(3<arguments.length){C=0;for(var G=Array(arguments.length-3);C<G.length;)G[C]=arguments[C+3],++C;C=new $CLJS.w(G,0,null)}return t.call(this,u,v,x,C)}function t(u,v,x,A){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ne.M(a,u,v,x,A),$CLJS.Ne.M(b,u,v,x,A)],null)}m.A=3;m.B=function(u){var v=$CLJS.z(u);u=$CLJS.B(u);var x=$CLJS.z(u);u=$CLJS.B(u);var A=$CLJS.z(u);u=$CLJS.Hc(u);return t(v,x,A,u)};m.l=t;return m}();k=function(m,t,u,v){switch(arguments.length){case 0:return f.call(this);
case 1:return e.call(this,m);case 2:return d.call(this,m,t);case 3:return c.call(this,m,t,u);default:var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.w(A,0,null)}return l.l(m,t,u,x)}throw Error("Invalid arity: "+arguments.length);};k.A=3;k.B=l.B;k.o=f;k.h=e;k.g=d;k.j=c;k.l=l.l;return k}()};$CLJS.EX=function(a,b){return $CLJS.PK(a)(b,$CLJS.xf,$CLJS.xf)};
Ona=function(a,b,c){return $CLJS.cb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.aF,b),$CLJS.Wr(2,2,c))};Pna=function(a,b){var c=$CLJS.Il(a,$CLJS.EP);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Cb(c);b=$CLJS.gs.g?$CLJS.gs.g(c,b):$CLJS.gs.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.EP,b)}return a};Qna=function(a){return $CLJS.bf.g($CLJS.KU(a),$CLJS.LU(a))};
FX=function(a,b,c){var d=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Dr,null,$CLJS.Wk,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.st(2,c):$CLJS.st(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?CX(2,c):CX(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.uW.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.vd(k)?$CLJS.Wf.j($CLJS.xf,$CLJS.fk.h($CLJS.cl),l):$CLJS.td(k)?$CLJS.Jz($CLJS.Sa,l):l}):$CLJS.Xj.g(a,f)};
Sna=function(a){return $CLJS.cb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return FX(b,Rna,d)},a,Qna(a))};
Tna=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.rK.h(a),b);a:{var d=c,e=$CLJS.PD;$CLJS.PD=!0;try{for(var f=$CLJS.xf;;){var k=$CLJS.z($CLJS.cf.g($CLJS.DX($CLJS.Si,$CLJS.Rk),$CLJS.ff($CLJS.ek.j(GX,$CLJS.z,$CLJS.Rk),$CLJS.Hr.h($CLJS.EX($CLJS.jV,d)))));if($CLJS.n(k)){var l=k,m=$CLJS.I(l,0,null),t=$CLJS.I(l,1,null),u=FX(d,m,t);l=$CLJS.xh;var v=l.l,x=m;var A=$CLJS.n(x)?x:$CLJS.UK($CLJS.EX($CLJS.jV,d));var C=v.call(l,$CLJS.H([A]));console.warn("Clean: Removing bad clause due to error!",t,C,$CLJS.tV($CLJS.z($CLJS.Zs(d,
u))));if($CLJS.E.g(u,d)){var G=d;break a}l=u;var J=$CLJS.Yd.g(f,new $CLJS.P(null,2,5,$CLJS.Q,[m,t],null));d=l;f=J}else{G=d;break a}}}finally{$CLJS.PD=e}G=void 0}e=Sna(G);if($CLJS.E.g(c,e)){if($CLJS.E.g(b,$CLJS.D($CLJS.rK.h(a))-1))return a;b+=1}else a=$CLJS.hk.M(a,$CLJS.rK,$CLJS.R,b,e)}};Una=function(a){var b=$CLJS.GV();return $CLJS.gk.g(function(c){return $CLJS.E.g($CLJS.XD.h(c),"__join")?$CLJS.hk.j(c,$CLJS.XD,b):c},a)};
Vna=function(a){return"string"===typeof $CLJS.dO.h(a)?$CLJS.Xj.g($CLJS.R.j(a,$CLJS.MU,$CLJS.EV($CLJS.dO.h(a))),$CLJS.dO):a};HX=function(){return $CLJS.fk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.he(b))?(b=$CLJS.de(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};IX=function(a){return $CLJS.Wf.j($CLJS.N,HX(),a)};
KX=function(a){return $CLJS.Be($CLJS.Wf.j($CLJS.N,$CLJS.ek.g(HX(),$CLJS.fk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.oi)})),$CLJS.Dd(a,$CLJS.JX)?$CLJS.Xj.l(a,$CLJS.JX,$CLJS.H([$CLJS.tA])):a))};
MX=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d=$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.h($CLJS.LX),c);a=$CLJS.E.g(a,$CLJS.UE)&&$CLJS.B(c)?$CLJS.Yd.g($CLJS.od(d),new $CLJS.h(null,1,[$CLJS.Oh,$CLJS.nd(d)],null)):d;b=$CLJS.Be(KX(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tL,a,b],null):a};Wna=function(a){return $CLJS.Wf.j($CLJS.xf,$CLJS.ek.g($CLJS.cf.h(function(b){return $CLJS.Ez(b,$CLJS.EA)}),$CLJS.cf.h($CLJS.LX)),$CLJS.AV.h(a))};
NX=function(a){a=$CLJS.O(a);a=$CLJS.K.g(a,$CLJS.rK);a=$CLJS.z($CLJS.cb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.LX.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.GP,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.QN,Wna(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.mU.h(c)],null)},null,a));return $CLJS.n($CLJS.hP.h(a))?$CLJS.xU(a,new $CLJS.h(null,1,[$CLJS.hP,$CLJS.HE],null)):a};
OX=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.K.g(a,b)),1)?$CLJS.RV(a,b,$CLJS.ek.g($CLJS.LX,$CLJS.z)):a;a=1<$CLJS.D($CLJS.K.g(a,b))?$CLJS.RV(d,b,function(e){return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lr],null),$CLJS.cf.h($CLJS.LX),e)}):d;return $CLJS.xU(a,$CLJS.Ae([b,c]))};Xna=function(a){var b=$CLJS.MU.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Xj.g(a,$CLJS.MU),$CLJS.dO,["card__",$CLJS.p.h(b)].join("")):a};
$CLJS.JX=new $CLJS.M("metabase.lib.query","transformation-added-base-type","metabase.lib.query/transformation-added-base-type",121891381);Yna=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);Zna=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);PX=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Rna=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);
QX=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);RX=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.SX=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);$na=new $CLJS.M(null,"m","m",1632677161);TX=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var GX,aoa,boa,coa,doa,eoa,foa,goa,hoa,ioa,joa;$CLJS.UX=$CLJS.N;$CLJS.VX=$CLJS.N;GX=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.gV,null,$CLJS.TD,null,$CLJS.CM,null,$CLJS.YO,null,$CLJS.$E,null,$CLJS.MQ,null,$CLJS.aF,null],null),null);aoa=$CLJS.Se($CLJS.N);boa=$CLJS.Se($CLJS.N);coa=$CLJS.Se($CLJS.N);doa=$CLJS.Se($CLJS.N);eoa=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Qi,$CLJS.fB],null),$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.WX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.WA,eoa,aoa,boa,coa,doa);$CLJS.WX.m(null,$CLJS.Oh,function(a){if($CLJS.vd(a)&&$CLJS.z(a)instanceof $CLJS.M){var b=$CLJS.nd(a);var c=$CLJS.td(b);a=c?$CLJS.od(a):a;var d=$CLJS.y(a);a=$CLJS.z(d);d=$CLJS.B(d);b=$CLJS.nV($CLJS.Wf.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.cf.h($CLJS.WX),d))}else b=a;return b});$CLJS.WX.m(null,$CLJS.fV,function(a){return a});
$CLJS.WX.m(null,$CLJS.TU,function(a){var b=$CLJS.WX.h($CLJS.aF.h(a)),c=$CLJS.Be($CLJS.gk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.xV($CLJS.WX.h(t),u)},$CLJS.YO.h(a))),d=$CLJS.Wf.j($CLJS.N,$CLJS.Kl(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.K.g(u,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.Wf.j($CLJS.N,$CLJS.Kl(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.K.g(u,$CLJS.yD);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.VX,k=$CLJS.UX;$CLJS.VX=d;$CLJS.UX=e;try{var l=Ona(Vna(a),b,$CLJS.H([$CLJS.YO,c])),m=$CLJS.cb(function(t,u){return $CLJS.Ra($CLJS.K.g(t,u))?t:$CLJS.hk.j(t,u,$CLJS.WX)},l,$CLJS.Yj.l(GX,$CLJS.aF,$CLJS.H([$CLJS.YO])));return $CLJS.n($CLJS.CM.h(m))?$CLJS.hk.j(m,$CLJS.CM,Una):m}finally{$CLJS.UX=k,$CLJS.VX=f}});$CLJS.WX.m(null,$CLJS.$U,function(a){return Pna(a,function(b){return $CLJS.RV(b,$CLJS.EE,$CLJS.WX)})});
$CLJS.WX.m(null,$CLJS.mK,function(a){a=$CLJS.hk.j($CLJS.hk.j(a,$CLJS.uK,$CLJS.WX),$CLJS.rK,$CLJS.WX);var b=$CLJS.n($CLJS.TD.h(a))?$CLJS.hk.j(a,$CLJS.TD,function(c){return $CLJS.ol(c)?$CLJS.gk.g($CLJS.WX,c):$CLJS.mh.h(c)}):a;return $CLJS.Ra($CLJS.XD.h(a))?$CLJS.R.j(b,$CLJS.XD,"__join"):b});$CLJS.WX.m(null,$CLJS.nA,function(a){return $CLJS.gk.g($CLJS.WX,a)});
$CLJS.WX.m(null,$CLJS.pA,function(a){return $CLJS.n($CLJS.Si.h(a))?Tna($CLJS.R.j($CLJS.hk.j($CLJS.BV(a),$CLJS.rK,function(b){return $CLJS.gk.g($CLJS.WX,b)}),$CLJS.SX,!0)):$CLJS.gs(a,$CLJS.WX)});$CLJS.WX.m(null,$CLJS.lF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.td(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.nV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lF,a,b],null))});
$CLJS.WX.m(null,$CLJS.ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xU(a,new $CLJS.h(null,3,[$CLJS.cC,$CLJS.tA,$CLJS.CN,$CLJS.sj,$CLJS.UQ,$CLJS.tD],null));var c=$CLJS.R.j;var d=$CLJS.oi.h(a);$CLJS.n(d)||(d=$CLJS.tA.h(a),d=$CLJS.n(d)?d:$CLJS.ND(b));a=c.call($CLJS.R,a,$CLJS.oi,d);return $CLJS.nV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,a,b],null))});
$CLJS.WX.m(null,$CLJS.UE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.Oh.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UE,$CLJS.Xj.g(c,$CLJS.Oh),$CLJS.gk.g($CLJS.WX,b)],null);b=$CLJS.nV(b);return null!=a?$CLJS.Yd.g(b,$CLJS.WX.h(a)):b});$CLJS.WX.m(null,$CLJS.qA,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.nV(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.WX.m(null,$CLJS.aF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null),e=$CLJS.Q;var f=$CLJS.K.g($CLJS.VX,c);if(!$CLJS.n(f))throw $CLJS.Uh(["Error converting :aggregation reference: no aggregation at index ",$CLJS.p.h(c)].join(""),new $CLJS.h(null,1,[$CLJS.XQ,a],null));return $CLJS.nV(new $CLJS.P(null,3,5,e,[b,d,f],null))});
$CLJS.WX.m(null,$CLJS.tL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.WX.h(b);var c=$CLJS.y(b);b=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.wk.l($CLJS.H([c,a]))],null),d)});foa=$CLJS.Se($CLJS.N);goa=$CLJS.Se($CLJS.N);hoa=$CLJS.Se($CLJS.N);ioa=$CLJS.Se($CLJS.N);joa=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Qi,$CLJS.fB],null),$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.LX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.WA,joa,foa,goa,hoa,ioa);
$CLJS.LX.m(null,$CLJS.Oh,function(a){if($CLJS.vd(a)&&$CLJS.z(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);if($CLJS.td(a)){c=$CLJS.Wf.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.bf.g;d=$CLJS.cf.g($CLJS.LX,d);a=KX(a);a=c.call($CLJS.Wf,b,e.call($CLJS.bf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.cf.g($CLJS.LX,$CLJS.ae(a,d)))}else a=$CLJS.td(a)?$CLJS.gs(IX(a),$CLJS.LX):
a;return a});for(var XX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[TX,RX],null)),YX=null,ZX=0,$X=0;;)if($X<ZX){var koa=YX.X(null,$X);$CLJS.rE(koa,QX);$X+=1}else{var aY=$CLJS.y(XX);if(aY){var bY=aY;if($CLJS.wd(bY)){var cY=$CLJS.ic(bY),loa=$CLJS.jc(bY),moa=cY,noa=$CLJS.D(cY);XX=loa;YX=moa;ZX=noa}else{var ooa=$CLJS.z(bY);$CLJS.rE(ooa,QX);XX=$CLJS.B(bY);YX=null;ZX=0}$X=0}else break}
for(var dY=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Rw,$CLJS.fF,$CLJS.uF,$CLJS.QE,$CLJS.Bj,$CLJS.yF,$CLJS.si,$CLJS.XE,$CLJS.sF,$CLJS.AF,$CLJS.xF,$CLJS.FF],null)),eY=null,fY=0,gY=0;;)if(gY<fY){var poa=eY.X(null,gY);$CLJS.rE(poa,TX);gY+=1}else{var hY=$CLJS.y(dY);if(hY){var iY=hY;if($CLJS.wd(iY)){var jY=$CLJS.ic(iY),qoa=$CLJS.jc(iY),roa=jY,soa=$CLJS.D(jY);dY=qoa;eY=roa;fY=soa}else{var toa=$CLJS.z(iY);$CLJS.rE(toa,TX);dY=$CLJS.B(iY);eY=null;fY=0}gY=0}else break}
for(var kY=$CLJS.y($CLJS.tf([$CLJS.Oq,$CLJS.Zr,$CLJS.Pq,$CLJS.GF,$CLJS.UE,$CLJS.oF,$CLJS.LE,$CLJS.IF,$CLJS.CE,$CLJS.ZE,$CLJS.GE,$CLJS.jF,$CLJS.qF,$CLJS.IE,$CLJS.YA,$CLJS.ZA,$CLJS.Fx,$CLJS.ZH,$CLJS.dI,$CLJS.TH,$CLJS.$A,$CLJS.YH,$CLJS.RH,$CLJS.gI,$CLJS.lI,$CLJS.iI,$CLJS.XH,$CLJS.nI,$CLJS.aB,$CLJS.dB,$CLJS.KE,$CLJS.DE,$CLJS.JF,$CLJS.ZG,$CLJS.nF,$CLJS.Pw,$CLJS.BF,$CLJS.DF,$CLJS.KF,$CLJS.js,$CLJS.ps],!0)),lY=null,mY=0,nY=0;;)if(nY<mY){var uoa=lY.X(null,nY);$CLJS.rE(uoa,RX);nY+=1}else{var oY=$CLJS.y(kY);
if(oY){var pY=oY;if($CLJS.wd(pY)){var qY=$CLJS.ic(pY),voa=$CLJS.jc(pY),woa=qY,xoa=$CLJS.D(qY);kY=voa;lY=woa;mY=xoa}else{var yoa=$CLJS.z(pY);$CLJS.rE(yoa,RX);kY=$CLJS.B(pY);lY=null;mY=0}nY=0}else break}$CLJS.LX.m(null,QX,function(a){return MX(a)});$CLJS.LX.m(null,$CLJS.pA,function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.ek.g(HX(),$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.LX.h(b)],null)})),a)});
$CLJS.LX.m(null,$CLJS.aF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.td(b))try{var d=KX(b);b=$CLJS.Q;var e=$CLJS.UX,f=$CLJS.K.j(e,c,PX);if($CLJS.E.g(f,PX))throw $CLJS.Uh(["Unable to find key ",$CLJS.xh.l($CLJS.H([c]))," in map."].join(""),new $CLJS.h(null,2,[$na,e,$CLJS.Vv,c],null));var k=new $CLJS.P(null,2,5,b,[$CLJS.aF,f],null);return $CLJS.y(d)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tL,k,d],null):k}catch(l){throw c=l,d=$CLJS.rU(c),d=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",
d):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",d),$CLJS.Th(d,new $CLJS.h(null,1,[$CLJS.V,a],null),c);}else return a});$CLJS.LX.m(null,$CLJS.nA,function(a){return $CLJS.gk.g($CLJS.LX,a)});
$CLJS.LX.m(null,$CLJS.lF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.td(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lF,$CLJS.LX.h(a),KX(b)],null)});
$CLJS.LX.m(null,$CLJS.ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=KX($CLJS.xU(b,new $CLJS.h(null,3,[$CLJS.tA,$CLJS.cC,$CLJS.sj,$CLJS.CN,$CLJS.tD,$CLJS.UQ],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,a,b],null)});
$CLJS.LX.m(null,$CLJS.mK,function(a){var b=IX(a);a=0==$CLJS.XD.h(a).lastIndexOf("__join",0)?$CLJS.Xj.g(b,$CLJS.XD):b;return $CLJS.wk.l($CLJS.H([$CLJS.gs($CLJS.Xj.l(a,$CLJS.rK,$CLJS.H([$CLJS.uK])),$CLJS.LX),OX($CLJS.Hl(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.uK],null)),$CLJS.uK,$CLJS.OQ),NX(a)]))});
$CLJS.LX.m(null,$CLJS.TU,function(a){var b=$CLJS.aF.h(a),c=$CLJS.Wf.j($CLJS.N,$CLJS.Kl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.K.g(k,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Kl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.K.g(k,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.VX,e=$CLJS.UX;$CLJS.VX=c;$CLJS.UX=b;try{return $CLJS.cb(function(f,k){return $CLJS.RV(f,
k,$CLJS.LX)},OX($CLJS.RV($CLJS.RV(Xna(IX(a)),$CLJS.aF,function(f){return $CLJS.gk.g(MX,f)}),$CLJS.YO,function(f){return $CLJS.Wf.g($CLJS.N,function(){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.wd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.id(u,A),G=$CLJS.LX.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wV(C),$CLJS.E.g($CLJS.ej,$CLJS.z(G))?$CLJS.dd(G):G],null);x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),
m($CLJS.jc(t))):$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);u=$CLJS.LX.h(x);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wV(x),$CLJS.E.g($CLJS.ej,$CLJS.z(u))?$CLJS.dd(u):u],null),m($CLJS.Hc(t)))}return null}},null,null)}(f)}())}),$CLJS.gV,$CLJS.PE),$CLJS.Yj.l(GX,$CLJS.aF,$CLJS.H([$CLJS.gV,$CLJS.YO])))}finally{$CLJS.UX=e,$CLJS.VX=d}});$CLJS.LX.m(null,$CLJS.$U,function(a){return $CLJS.gs(IX(a),$CLJS.LX)});
$CLJS.LX.m(null,$CLJS.fV,function(a){try{var b=IX(a),c=$CLJS.tM.h(b),d=NX(b),e=$CLJS.E.g($CLJS.Vs.h($CLJS.fd($CLJS.rK.h(a))),$CLJS.$U)?$CLJS.hP:$CLJS.HE;return $CLJS.wk.l($CLJS.H([$CLJS.Xj.l(b,$CLJS.rK,$CLJS.H([$CLJS.tM,$CLJS.SX])),function(){var k=$CLJS.Ae([$CLJS.Si,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.tM,c):k}()]))}catch(k){var f=k;throw $CLJS.Th(function(){var l=$CLJS.rU(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.HE,a],null),f);}});
$CLJS.rY=function(){function a(d,e,f){f=$CLJS.BX(null,$CLJS.Ly(f,$CLJS.H([$CLJS.ki,!0])));var k=$CLJS.DV(d,e);k=$CLJS.O(k);var l=$CLJS.K.g(k,$CLJS.aF);k=$CLJS.Wf.j($CLJS.N,$CLJS.Kl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.K.g(x,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.Wf.j($CLJS.N,$CLJS.Kl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.K.g(x,$CLJS.yD);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.VX,t=$CLJS.UX;$CLJS.VX=k;$CLJS.UX=l;try{try{return $CLJS.WX.h(f)}catch(v){var u=v;throw $CLJS.Th(function(){var x=$CLJS.rU(u);return $CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.HE,d,$CLJS.EK,e,Zna,f,Yna,$CLJS.VX],null),u);}}finally{$CLJS.UX=t,$CLJS.VX=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();