var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var vG,wG,yG,zG,xha,yha,zha,AG,xG;$CLJS.tG=function(a,b){return $CLJS.cc($CLJS.cb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.Yd.g($CLJS.K.j(c,e,$CLJS.xf),d))},$CLJS.ac($CLJS.N),b))};$CLJS.uG=function(a,b){return $CLJS.cb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Oc(d):null},null,b)};
vG=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.Dz(b,$CLJS.Ck)?$CLJS.eG:$CLJS.Dz(b,$CLJS.Qk)?$CLJS.aG:$CLJS.Dz(b,$CLJS.Jk)?$CLJS.cG:null;return $CLJS.n(b)?$CLJS.qE(b,a):!0};
wG=function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);a=$CLJS.tG(function(d){return $CLJS.Dz($CLJS.ND(d),$CLJS.aC)},a);var b=$CLJS.O(a);a=$CLJS.K.g(b,!1);b=$CLJS.K.g(b,!0);if($CLJS.dk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.ND($CLJS.z(a));return $CLJS.Me(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(vG(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
yG=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,1,[$CLJS.ts,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.wD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,xG],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,xG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.Es,function(b){b=$CLJS.O(b);b=$CLJS.K.g(b,$CLJS.ej);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(wG(b))].join("")}],null),$CLJS.Oe(wG)],null)],null)};
zG=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hj,new $CLJS.h(null,1,[$CLJS.ts,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.wD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jE],null)],null)],null)],null)};
xha=function(a){return $CLJS.Md($CLJS.tE,$CLJS.cf.g(function(b){var c=$CLJS.ND(b),d=$CLJS.Dz(c,$CLJS.$D);b=d?$CLJS.qE($CLJS.pF,b):d;return $CLJS.n(b)?$CLJS.oj:c},a))};yha=function(a){a=$CLJS.uG(function(b){return!$CLJS.Dz(b,$CLJS.aC)},$CLJS.cf.g($CLJS.ND,a));return $CLJS.Dz(a,$CLJS.$D)?$CLJS.tj:a};
zha=function(a,b){return $CLJS.n($CLJS.Me(function(c){return $CLJS.Dz($CLJS.ND(c),$CLJS.aC)},b))?yha(b):$CLJS.E.g(a,$CLJS.Zr)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Le(function(c){return $CLJS.Dz($CLJS.ND(c),$CLJS.Ck)},b)||$CLJS.Le(function(c){return $CLJS.Dz($CLJS.ND(c),$CLJS.Jk)},b))?$CLJS.aC:xha(b)};AG=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.BG=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);xG=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,xG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.ts,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=$CLJS.ND(a);return $CLJS.Le(function(d){return vG(d,c)},b)}],null)],null));
$CLJS.Y(AG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jE],null)],null)],null));
var Aha=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hj,new $CLJS.h(null,1,[$CLJS.ts,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,$CLJS.Zr],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.wD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)],null)],
null);$CLJS.LF.g($CLJS.Oq,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,yG($CLJS.Oq),zG($CLJS.Oq)],null));$CLJS.LF.g($CLJS.Zr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,yG($CLJS.Zr),Aha,zG($CLJS.Zr)],null));$CLJS.AE($CLJS.Pq,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,AG],null)]));$CLJS.AE($CLJS.GF,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,AG],null)]));
for(var CG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oq,$CLJS.Zr,$CLJS.Pq],null)),DG=null,EG=0,FG=0;;)if(FG<EG){var Bha=DG.X(null,FG);$CLJS.rE(Bha,$CLJS.BG);FG+=1}else{var GG=$CLJS.y(CG);if(GG){var HG=GG;if($CLJS.wd(HG)){var IG=$CLJS.ic(HG),Cha=$CLJS.jc(HG),Dha=IG,Eha=$CLJS.D(IG);CG=Cha;DG=Dha;EG=Eha}else{var Fha=$CLJS.z(HG);$CLJS.rE(Fha,$CLJS.BG);CG=$CLJS.B(HG);DG=null;EG=0}FG=0}else break}
$CLJS.MD.m(null,$CLJS.BG,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);$CLJS.z(b);b=$CLJS.B(b);return zha(a,b)});$CLJS.yE($CLJS.LE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jE],null)],null)]));$CLJS.rE($CLJS.LE,$CLJS.dE);
for(var JG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IF,$CLJS.CE,$CLJS.ZE],null)),KG=null,LG=0,MG=0;;)if(MG<LG){var Gha=KG.X(null,MG);$CLJS.yE(Gha,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jE],null)],null)]));MG+=1}else{var NG=$CLJS.y(JG);if(NG){var OG=NG;if($CLJS.wd(OG)){var PG=$CLJS.ic(OG),Hha=$CLJS.jc(OG),Iha=PG,Jha=$CLJS.D(PG);JG=Hha;KG=Iha;LG=Jha}else{var Kha=$CLJS.z(OG);$CLJS.yE(Kha,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jE],null)],null)]));JG=$CLJS.B(OG);KG=null;LG=0}MG=0}else break}
for(var QG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GE,$CLJS.jF,$CLJS.qF],null)),RG=null,SG=0,TG=0;;)if(TG<SG){var Lha=RG.X(null,TG);$CLJS.yE(Lha,$CLJS.H([$CLJS.Zr,$CLJS.Zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jE],null)],null)]));TG+=1}else{var UG=$CLJS.y(QG);if(UG){var VG=UG;if($CLJS.wd(VG)){var WG=$CLJS.ic(VG),Mha=$CLJS.jc(VG),Nha=WG,Oha=$CLJS.D(WG);QG=Mha;RG=Nha;SG=Oha}else{var Pha=$CLJS.z(VG);$CLJS.yE(Pha,$CLJS.H([$CLJS.Zr,$CLJS.Zi,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jE],null)],null)]));QG=$CLJS.B(VG);RG=null;SG=0}TG=0}else break}$CLJS.yE($CLJS.IE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jE],null)],null)]));
$CLJS.MD.m(null,$CLJS.IE,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.Dz($CLJS.ND(b),$CLJS.Zi)&&$CLJS.Dz($CLJS.ND(a),$CLJS.Zi)?$CLJS.Zi:$CLJS.LC});