var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.expression.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var V0,W0,$0,Ppa,Opa,Qpa;$CLJS.S0=function(a,b){return $CLJS.D(a)<=$CLJS.D(b)&&$CLJS.Le(function(c){return $CLJS.Dd(b,c)},a)};$CLJS.T0=function(a,b){return $CLJS.Jz($CLJS.Oe(a),b)};$CLJS.U0=function(a){var b;a:for(b=a;;)if($CLJS.td(b))b=$CLJS.Ii.h(b);else{b=$CLJS.Bd(b)?b:null;break a}if($CLJS.n(b))return b;throw Error($CLJS.fD("Not something with an ID: {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))])));};
V0=function(a){if(null!=a&&null!=a.sg)a=a.sg(a);else{var b=V0[$CLJS.va(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=V0._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.$a("MetadataProvider.database",a);}return a};W0=function(a,b){if(null!=a&&null!=a.tg)a=a.tg(a,b);else{var c=W0[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=W0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.field",a);}return a};$CLJS.X0=function(a){return V0($CLJS.a_(a))};
$CLJS.Y0=function(a,b){return W0($CLJS.a_(a),b)};$CLJS.Z0=function(a){var b=$CLJS.DV(a,0),c=$CLJS.O(b),d=$CLJS.K.g(c,$CLJS.dO),e=$CLJS.K.g(c,$CLJS.MU);return $CLJS.Ad(function(){var f=$CLJS.X0(a);$CLJS.n(f)?(f=$CLJS.O(f),f=$CLJS.K.g(f,$CLJS.Ii),f=$CLJS.E.g($CLJS.TO.h(a),f)):f=null;if($CLJS.n(f)){f=$CLJS.n(d)?$CLJS.b_(a,d):d;if($CLJS.n(f))return f;f=$CLJS.n(e)?$CLJS.d_(a,e):e;return $CLJS.n(f)?f:$CLJS.E.g($CLJS.Vs.h(c),$CLJS.$U)&&$CLJS.E.g($CLJS.ZY,$CLJS.$Y.h($CLJS.X0(a)))}return f}())};
$0=function(a,b){return function f(d,e){try{if($CLJS.vd(e)&&3===$CLJS.D(e))try{var k=$CLJS.F(e,0);if($CLJS.ce(k,$CLJS.lF))try{var l=$CLJS.F(e,1);if($CLJS.n($CLJS.dD($CLJS.td,$CLJS.Oe($CLJS.dD($CLJS.tA,$CLJS.oi)))(l)))try{var m=$CLJS.F(e,2);if($CLJS.n($CLJS.dD($CLJS.Bd,$CLJS.wl)(m))){var t=$CLJS.F(e,2),u=$CLJS.F(e,1);return $CLJS.n($CLJS.Me(new $CLJS.Rg(null,new $CLJS.h(null,1,[Opa,null],null),null),d))?e:$CLJS.hk.v(e,1,$CLJS.wk,null!=$CLJS.tA.h(u)&&$CLJS.dk.g($CLJS.tA.h(u),$CLJS.tA.h($CLJS.Y0(b,t)))?
null:$CLJS.wk.l($CLJS.H([$CLJS.Dd(u,$CLJS.tA)?null:new $CLJS.h(null,1,[$CLJS.JX,!0],null),$CLJS.Hl($CLJS.Y0(b,t),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.oi],null))])))}throw $CLJS.Z;}catch(x){if(x instanceof Error){var v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof
Error){v=x;if(v===$CLJS.Z)return $CLJS.WV(f,d,e);throw v;}throw x;}}($CLJS.xf,a)};Ppa=new $CLJS.M(null,"is-native","is-native",-1797717934);Opa=new $CLJS.M("mbql","stage-metadata","mbql/stage-metadata",1090845629);Qpa=new $CLJS.M(null,"is-editable","is-editable",269018020);$CLJS.P0.m(null,$CLJS.fV,function(a){return $CLJS.O0(a,new $CLJS.h(null,2,[$CLJS.Si,$CLJS.mh,$CLJS.rK,$CLJS.Qe($CLJS.gk,$CLJS.P0)],null))});$CLJS.Q_.m(null,$CLJS.fV,function(){throw $CLJS.Uh("You can't calculate a metadata map for a query! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});$CLJS.S_.m(null,$CLJS.fV,function(a,b,c,d){return $CLJS.T_.v(a,b,$CLJS.DV(c,b),d)});$CLJS.K_.m(null,$CLJS.fV,function(a,b,c,d){return $CLJS.j_.v(a,b,$CLJS.DV(c,b),d)});
$CLJS.R_.m(null,$CLJS.fV,function(a,b,c){a=$CLJS.DV(c,0);a=$CLJS.E.g($CLJS.Vs.h(a),$CLJS.$U);return new $CLJS.h(null,2,[Ppa,a,Qpa,$CLJS.Z0(c)],null)});$CLJS.a1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j($CLJS.N,$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.query","can-run-method"),function(f){return $CLJS.Vs.h($CLJS.DV(f,0))},e,a,b,c,d)}();$CLJS.a1.m(null,$CLJS.Oh,function(){return!0});
$CLJS.b1=function(){function a(d,e,f){return new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.fV,$CLJS.gD,$CLJS.a_(e),$CLJS.TO,d,$CLJS.rK,f],null)}function b(d,e){var f=$CLJS.Ii.h($CLJS.X0(d));return c.j?c.j(f,d,e):c.call(null,f,d,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.c1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Qi,$CLJS.fB],null),$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.query","query-method"),function(f,k){return $CLJS.WA(k)},e,a,b,c,d)}();$CLJS.c1.m(null,$CLJS.pA,function(a,b){a:{var c=$CLJS.PD;$CLJS.PD=!0;try{var d=$CLJS.WX.h(b);break a}finally{$CLJS.PD=c}d=void 0}b=$0(d,a);return $CLJS.b1.g(a,$CLJS.rK.h(b))});
$CLJS.c1.m(null,$CLJS.fV,function(a,b){var c=$CLJS.O(b);b=$CLJS.K.g(c,$CLJS.SX);var d=$CLJS.a_(a),e=$CLJS.Xj.g($CLJS.R.j(c,$CLJS.gD,d),$CLJS.SX);a=$CLJS.rK.h(e);return $CLJS.n(b)?$CLJS.R.j(e,$CLJS.rK,$CLJS.gk.g(function(f){var k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return function u(m,t){try{if($CLJS.vd(t)&&3===$CLJS.D(t))try{var v=$CLJS.F(t,0);if($CLJS.ce(v,$CLJS.qA))try{var x=$CLJS.F(t,1);if($CLJS.n($CLJS.dD($CLJS.td,$CLJS.Oe($CLJS.dD($CLJS.tA,$CLJS.oi)))(x))){var A=$CLJS.F(t,1),C=$CLJS.F(t,2),
G=function(){try{return $CLJS.T0(function(S){return $CLJS.E.g($CLJS.cj,S)},$CLJS.Hl($CLJS.dd($CLJS.Gpa.j(e,k,C)),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.oi],null)))}catch(S){return null}}();return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,$CLJS.wk.l($CLJS.H([G,A])),C],null)}throw $CLJS.Z;}catch(S){if(S instanceof Error){var J=S;if(J===$CLJS.Z)throw $CLJS.Z;throw J;}throw S;}else throw $CLJS.Z;}catch(S){if(S instanceof Error){J=S;if(J===$CLJS.Z)throw $CLJS.Z;throw J;}throw S;}else throw $CLJS.Z;
}catch(S){if(S instanceof Error){J=S;if(J===$CLJS.Z)return $CLJS.WV(u,m,t);throw J;}throw S;}}($CLJS.xf,$0(f,d))},$CLJS.Gl($CLJS.xr,a))):e});$CLJS.c1.m(null,$CLJS.SJ,function(a,b){return $CLJS.b1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.TU,$CLJS.dO,$CLJS.U0(b)],null)],null))});$CLJS.c1.m(null,$CLJS.iK,function(a,b){return $CLJS.b1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.TU,$CLJS.MU,$CLJS.U0(b)],null)],null))});