var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var LD,RD,Oga,Pga,Qga,Rga,Sga,Tga,gE;
$CLJS.JD=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.ez([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.KD=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.fD(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)};LD=function(a){return $CLJS.vd(a)&&$CLJS.z(a)instanceof $CLJS.M};
$CLJS.ND=function(a){var b=function(){var c=LD(a);return c?(c=$CLJS.td($CLJS.dd(a)))?(c=$CLJS.oi.h($CLJS.dd(a)),$CLJS.n(c)?c:$CLJS.tA.h($CLJS.dd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.MD.h(a)};
RD=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,2,[$CLJS.ts,"valid MBQL clause",$CLJS.Es,function(c){c=$CLJS.O(c);c=$CLJS.K.g(c,$CLJS.ej);return["invalid MBQL clause: ",$CLJS.xh.l($CLJS.H([c]))].join("")}],null),$CLJS.Oe(LD)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.ts,b],null),function(c){var d=$CLJS.PD;
$CLJS.n(d)?c=d:(c=$CLJS.ND(c),c=QD(c,a));return c}],null)],null)};$CLJS.SD=new $CLJS.M(null,"right-join","right-join",-56349359);Oga=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);Pga=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);Qga=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);
$CLJS.TD=new $CLJS.M(null,"fields","fields",-1932066230);$CLJS.UD=new $CLJS.M(null,"expr","expr",745722291);$CLJS.VD=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.WD=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.XD=new $CLJS.M(null,"alias","alias",-2039751630);
$CLJS.YD=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);$CLJS.ZD=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.$D=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.aE=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);
Rga=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.bE=new $CLJS.M(null,"left-join","left-join",-672831855);$CLJS.cE=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.dE=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);
$CLJS.eE=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);Sga=new $CLJS.r("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);Tga=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.fE=new $CLJS.M(null,"inner-join","inner-join",659431740);
gE=new $CLJS.M("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);$CLJS.OD=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);$CLJS.hE=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.iE=new $CLJS.M(null,"full-join","full-join",1305476385);
$CLJS.jE=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.kE=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);var QD;$CLJS.MD=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Qi,$CLJS.fB],null),$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.WA(f);return $CLJS.E.g(k,$CLJS.sA)?$CLJS.Za(f):k},e,a,b,c,d)}();
$CLJS.Y(Pga,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,$CLJS.$D],null),$CLJS.BD],null));$CLJS.MD.m(null,$CLJS.Oh,function(a){throw $CLJS.Uh($CLJS.fD("{0}: Don''t know how to determine the type of {1}",$CLJS.H([Sga,$CLJS.xh.l($CLJS.H([a]))])),new $CLJS.h(null,1,[$CLJS.UD,a],null));});$CLJS.MD.m(null,$CLJS.dE,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.ND(a)});
QD=function QD(a,b){return $CLJS.rd(a)?$CLJS.Me(function(d){return QD.g?QD.g(d,b):QD.call(null,d,b)},a):$CLJS.rd(b)?$CLJS.Me(function(d){return QD.g?QD.g(a,d):QD.call(null,a,d)},b):$CLJS.E.g(a,$CLJS.$D)?!0:$CLJS.Dz(a,b)};$CLJS.PD=!1;$CLJS.Y($CLJS.YD,RD($CLJS.mj,"expression returning a boolean"));$CLJS.Y($CLJS.hE,RD($CLJS.xj,"expression returning a string"));$CLJS.Y($CLJS.kE,RD($CLJS.Zi,"expression returning an integer"));$CLJS.Y(Qga,RD($CLJS.LC,"expression returning a non-integer real number"));
$CLJS.Y($CLJS.jE,RD($CLJS.oj,"expression returning a number"));$CLJS.Y(Rga,RD($CLJS.Ck,"expression returning a date"));$CLJS.Y(Tga,RD($CLJS.Qk,"expression returning a time"));$CLJS.Y(Oga,RD($CLJS.Jk,"expression returning a date time"));$CLJS.Y($CLJS.WD,RD($CLJS.tj,"expression returning a date, time, or date time"));$CLJS.lE=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.oj,null,$CLJS.xj,null,$CLJS.tj,null,$CLJS.mj,null],null),null);$CLJS.Y($CLJS.eE,RD($CLJS.lE,"an expression that can be compared with :\x3e or :\x3c"));
var Uga=new $CLJS.Rg(null,new $CLJS.h(null,9,[$CLJS.BB,null,$CLJS.oj,null,$CLJS.xj,null,$CLJS.fC,null,$CLJS.tj,null,$CLJS.cj,null,$CLJS.mj,null,$CLJS.AB,null,$CLJS.JC,null],null),null);$CLJS.gA.g($CLJS.xj,gE);$CLJS.gA.g($CLJS.fC,gE);$CLJS.gA.g($CLJS.AB,gE);$CLJS.Y(gE,RD(gE,"expression returning something emptyable (e.g. a string or BSON ID)"));$CLJS.Y($CLJS.cE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,RD(Uga,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.Y($CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,RD($CLJS.cj,"any type of expression")],null));
$CLJS.Y($CLJS.VD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.si,1],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.ZD],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hj,$CLJS.ur,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qD,$CLJS.vj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pq,$CLJS.ur],null)],null)],null)],null));